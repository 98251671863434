import React from 'react';
import { match } from '@formatjs/intl-localematcher';
import { navigate, withPrefix, WrapPageElementBrowserArgs } from 'gatsby';
import i18nConfig from './i18n.config.json';
import { TranslationContext } from '@dssf/component-library';
export { wrapRootElement } from './gatsby-browser+ssr';

export function wrapPageElement({ element, props }: WrapPageElementBrowserArgs) {
  // Redirect to locale subpath if no locale is specified or specified locale is not supported
  const lang = i18nConfig.supportedLanguages.find(lang => location.pathname.startsWith(withPrefix(`/${lang}/`)));
  if (lang) {
    // found valid locale, setting lang attribute
    document.querySelector('html').setAttribute('lang', lang);
  } else {
    // redirect to locale based on navigator language
    const locale = match([...navigator.languages], i18nConfig.supportedLanguages, i18nConfig.defaultLanguage);
    navigate(`/${locale}/${location.pathname.replace(withPrefix(''), '')}${location.search}`, { replace: true });
    return null;
  }

  const intl = props.pageContext.intl;

  return (
    <TranslationContext language={intl.language} defaultLanguage={intl.defaultLanguage} messages={intl.messages}>
      {element}
    </TranslationContext>
  );
}
