import React, { ReactNode } from 'react';
import { AuthProvider } from './src/context/auth';
import { GuidelinesAcceptedContextProvider } from './src/context/guidelinesAcceptedContext';
import { LocationProvider } from '@reach/router';
import { ThemeProvider } from '@dssf/component-library';
import { URLProvider } from './src/context/url';
import { SpaceConfigProvider } from './src/context/spaceConfig';
import { GeoblockingProvider } from './src/context/geoblocking';
import { ShowDialogsProvider } from './src/context/showDialogs';
import { CrossPageContentProvider } from './src/context/crossPageContent';
import { LayoutContextProvider } from './src/context/layout';

export function wrapRootElement({ element }: { element: ReactNode }) {
  return (
    <ThemeProvider>
      <LocationProvider>
        <URLProvider>
          <AuthProvider>
            <GeoblockingProvider>
              <GuidelinesAcceptedContextProvider>
                <CrossPageContentProvider>
                  <LayoutContextProvider>
                    <ShowDialogsProvider>
                      <SpaceConfigProvider>{element}</SpaceConfigProvider>
                    </ShowDialogsProvider>
                  </LayoutContextProvider>
                </CrossPageContentProvider>
              </GuidelinesAcceptedContextProvider>
            </GeoblockingProvider>
          </AuthProvider>
        </URLProvider>
      </LocationProvider>
    </ThemeProvider>
  );
}
