import { tileCategories, TileCategoryId } from '../../global/tileCategories';
import { SchemaTileDto } from '@askbosch/askbosch-fm-space-administration-openapi-npm';
import { CategorizedTiles } from '../types/categorizedTiles';
import { getIndexed } from '../services/getIndexed';
import useSWR from 'swr';
import { SpaceAdministrationService } from '../services/spaceAdministrationService';
import { useAuth } from '@dssf/component-library';

export const fallbackLLMId = 'GEMINI_BASIC';
export const fallbackSpaceId = 'PUBLIC';
export const fallbackSpaceProvider = 'PUBLIC';
export const PUBLIC_SPACE = 'public';

function getTileCategories(tile: SchemaTileDto): TileCategoryId[] {
  const categories = [
    (() => {
      switch (true) {
        case ['HALO', 'CONTRACT_EXPERT'].includes(tile.tileType ?? ''): // more important than private, etc.
          return TileCategoryId.bbm;
        case tile.provider === 'BRAIN': // more important than private, etc.
          return TileCategoryId.brain;
        case ['ADMIN', 'EDITOR'].includes(tile.role ?? ''):
          return TileCategoryId.managedPublic;
        case tile.kind === 'LLM':
          return TileCategoryId.llm;
        case tile.tileCategory === 'CONTENT_CREATION':
          return TileCategoryId.contentCreation;
        default:
          return TileCategoryId.public;
      }
    })(),
  ];

  if (tile.favorite) {
    categories.push(TileCategoryId.favourites);
  }
  if ((tile.featuredRank ?? 0) > 0) {
    categories.push(TileCategoryId.featured);
  }

  return categories;
}

export function useTiles() {
  const { token } = useAuth();

  const {
    data: tileData,
    mutate: reloadTiles,
    ...rest
  } = useSWR('tiles', () => SpaceAdministrationService.getTiles(token));

  // hide space of tiles
  const tiles =
    tileData?.tiles.filter(
      tile =>
        !tileData?.backendConfiguration?.spaceOfTilesId ||
        tile.spaceId !== tileData?.backendConfiguration?.spaceOfTilesId,
    ) ?? [];
  return {
    tiles,
    ...rest,
    //TODO: Fix type not to be any
    indexedTiles: getIndexed(tiles, 'tileId') as any,
    userAuthorizations: tileData?.userAuthorizations ?? {},
    reloadTiles,
    categorizedTiles: tiles.reduce(
      (s, tile) => {
        getTileCategories(tile).forEach(category => s[category].push(tile));
        return s;
      },
      Object.fromEntries(tileCategories.map(category => [category.id, []])) as unknown as CategorizedTiles,
    ) as CategorizedTiles,
    tilesBySpace: tiles.reduce(
      (s, tile) => {
        if (tile.spaceId) {
          if (!s[tile.spaceId]) {
            s[tile.spaceId] = [];
          }
          s[tile.spaceId].push(tile);
        }
        return s;
      },
      {} as { [spaceId: string]: SchemaTileDto[] },
    ),
    backendConfiguration: tileData?.backendConfiguration ?? { maxByodPdfFileSize: 0 },
  };
}
