import { AccessRequestActionDTO, FileDTO, HaloAcceptedParameters } from '../types/halo';
import { SpaceDTO } from '../types/spaceConfig';
import { ApiService } from './apiService';
import {
  SchemaFileDto,
  SchemaGetTilesDto,
  SchemaGraphApiUserDto,
  SchemaPostTileDto,
  SchemaSpaceDto,
  SchemaTileDetailsDto,
  SchemaTileSearchResultsDto,
} from '@askbosch/askbosch-fm-space-administration-openapi-npm';
import { SelectableFile } from '../types/selectableFile';

const filePathDelimiter = '/';

export class SpaceAdministrationService {
  static hiddenTileIds = process.env.GATSBY_HIDDEN_TILE_IDS
    ? process.env.GATSBY_HIDDEN_TILE_IDS.split(',').map(e => e.trim())
    : [];

  static buildUri(path: string): string {
    if (process.env.OVERRIDE_SPACE_ADMINISTRATION_URI) {
      return `${process.env.OVERRIDE_SPACE_ADMINISTRATION_URI}${path}`;
    }
    return `${process.env.GATSBY_API_BASE_URI!}${process.env.GATSBY_SPACE_ADMINISTRATION_SUB_URI!}${path}`;
  }

  static async getSpace(token: string, spaceId: string): Promise<SchemaSpaceDto> {
    return ApiService.request<SchemaSpaceDto>(this.buildUri(`/spaces/${spaceId}`), token);
  }

  static async getTiles(token: string): Promise<SchemaGetTilesDto> {
    const tiles = await ApiService.request<SchemaGetTilesDto>(this.buildUri('/tiles'), token);

    tiles.tiles = tiles.tiles.filter(
      tile => !(this.hiddenTileIds.includes(tile.tileId ?? '') && tile.role === 'VIEWER'),
    );
    return tiles;
  }

  static async getTile(token: string, tileId: string): Promise<SchemaTileDetailsDto> {
    return ApiService.request<SchemaTileDetailsDto>(this.buildUri(`/tiles/${tileId}`), token);
  }

  static async updateTile(token: string, data: SchemaPostTileDto): Promise<SchemaPostTileDto> {
    return ApiService.request(this.buildUri('/tiles'), token, {
      method: 'POST',
      json: data,
    });
  }

  static async deleteTile(token: string, tileId: string): Promise<void> {
    return ApiService.request(this.buildUri(`/tiles/${tileId}`), token, {
      method: 'DELETE',
      raw: true,
    });
  }

  static async searchTiles(token: string, searchTerm: string): Promise<SchemaTileSearchResultsDto> {
    return ApiService.request(this.buildUri(`/search?searchTerm=${encodeURIComponent(searchTerm)}`), token);
  }

  static async getSpaceDetails(token: string, spaceId: string): Promise<SpaceDTO> {
    return ApiService.request<SpaceDTO>(this.buildUri(`/spaces/${spaceId}`), token);
  }

  static getSources(token: string, spaceId: string): Promise<FileDTO[]> {
    return ApiService.request(this.buildUri(`/sources?spaceId=${spaceId}`), token);
  }

  static uploadSource(spaceId: string, token: string, source: { data?: Blob; fileName?: string; url?: string }) {
    const body = new FormData();
    Object.entries(source).forEach(([key, value]) => body.append(key, value));

    return ApiService.request(this.buildUri(`/sources?spaceId=${spaceId}`), token, {
      method: 'POST',
      body,
      raw: true,
    });
  }

  static deleteSource(token: string, spaceId: string, id: string): Promise<void> {
    return ApiService.request(this.buildUri(`/sources?spaceId=${spaceId}&fileId=${id}`), token, {
      method: 'DELETE',
      raw: true,
    });
  }

  static performAccessRequest(token: string, data: AccessRequestActionDTO) {
    return ApiService.request(this.buildUri('/access-requests/action'), token, {
      method: 'POST',
      json: data,
      raw: true,
    });
  }

  static async downloadSource(
    token: string,
    fileInfo: { fileId?: string; spaceId?: string; fileName?: string },
  ): Promise<Blob> {
    const queryParams = Object.entries(fileInfo)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    const response = await ApiService.request<Response>(this.buildUri(`/sources/download?${queryParams}`), token, {
      raw: true,
    });
    return response.blob();
  }

  static getHaloAcceptedParameters(token: string): Promise<HaloAcceptedParameters> {
    return ApiService.request(this.buildUri(`/external/acceptedHaloParameters`), token);
  }

  static async performGetContractAnalysisFiles(
    token: string,
    spaceId: string,
    searchTerm: string,
  ): Promise<SchemaFileDto[]> {
    return ApiService.request<SchemaFileDto[]>(
      this.buildUri(`/external/files?spaceId=${spaceId}&searchTerm=${encodeURIComponent(searchTerm)}`),
      token,
    );
  }

  static async getSelectableContractAnalysisFiles(
    token: string,
    spaceId: string,
    searchTerm: string,
  ): Promise<SelectableFile[]> {
    const files = await this.performGetContractAnalysisFiles(token, spaceId, searchTerm);
    return files.map(file => ({
      ...file,
      ...this.getFilePath(file.fileName ?? ''),
      selected: false,
    }));
  }

  static getFilePath(fileName: string): { name: string; path: string[] } {
    const parts = fileName.split(filePathDelimiter);
    return {
      name: parts.at(-1) ?? '',
      path: parts.slice(0, -1),
    };
  }

  static createFilePath(filePath: { name: string; path: string[] }): string {
    return [...filePath.path, filePath.name].join(filePathDelimiter);
  }

  static async searchBoschUsers(token: string, searchTerm: string): Promise<SchemaGraphApiUserDto[]> {
    return ApiService.request<SchemaGraphApiUserDto[]>(
      this.buildUri(`/users?searchTerm=${encodeURIComponent(searchTerm)}`),
      token,
    );
  }
}
