import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface DialogsContext {
  showNewFeaturesDialog: boolean | undefined;
  setShowNewFeaturesDialog: (state: boolean | undefined) => void;
}

const ShowDialogsContext = createContext<DialogsContext>({
  showNewFeaturesDialog: false,
  setShowNewFeaturesDialog: () => {},
});

export function useShowDialogs() {
  return useContext(ShowDialogsContext);
}

export function ShowDialogsProvider({ children }: { children: ReactNode }) {
  const [showNewFeaturesDialog, setShowNewFeaturesDialog] = useState<boolean | undefined>();

  return (
    <ShowDialogsContext.Provider value={{ showNewFeaturesDialog, setShowNewFeaturesDialog }}>
      {children}
    </ShowDialogsContext.Provider>
  );
}
