import React, { useState } from 'react';
import { UploadedChatMessageAttachment } from '../types/prompt';
import { OmnipromptTabId } from '../components/OmnipromptTabs/OmnipromptTabs';
import { StageService } from '../services/stageService';

export interface CrossPageContentContext {
  chatContent?: { uploadedAttachments: UploadedChatMessageAttachment[] };
  setChatContent: (data: { uploadedAttachments: UploadedChatMessageAttachment[] }) => void;
  omnipromptTab: OmnipromptTabId;
  setOmnipromptTab: (tab: OmnipromptTabId) => void;
}

const CrossPageContentContext = React.createContext<CrossPageContentContext>({
  setChatContent: () => {},
  omnipromptTab: 'chat',
  setOmnipromptTab: () => {},
});

export function useCrossPageContent() {
  return React.useContext(CrossPageContentContext);
}

export function CrossPageContentProvider({ children }: { children: React.ReactNode }) {
  const [chatContent, setChatContent] = React.useState<
    { uploadedAttachments: UploadedChatMessageAttachment[] } | undefined
  >(undefined);
  const [omnipromptTab, setOmnipromptTab] = useState<OmnipromptTabId>(
    !['alpha', 'beta'].includes(StageService.current) ? 'chat' : 'find',
  );

  return (
    <CrossPageContentContext.Provider value={{ chatContent, setChatContent, omnipromptTab, setOmnipromptTab }}>
      {children}
    </CrossPageContentContext.Provider>
  );
}
