import { getIndexed } from '../src/services/getIndexed';

export enum TileCategoryId {
  all = 'all',
  application = 'application',
  space = 'space',
  favourites = 'favourites',
  featured = 'featured',
  private = 'private',
  managedPublic = 'managedPublic',
  llm = 'llm',
  contentCreation = 'contentCreation',
  public = 'public',
  bbm = 'bbm',
  brain = 'brain',
  search = 'search',
}

export const tileCategories = [
  { id: TileCategoryId.all, name: 'Data.TileCategories.All.Title' },
  { id: TileCategoryId.favourites, name: 'Data.TileCategories.Favourites.Title' },
  {
    id: TileCategoryId.featured,
    name: 'Data.TileCategories.Featured.Title',
    description: 'Data.TileCategories.Featured.Description',
  },
  {
    id: TileCategoryId.llm,
    name: 'Data.TileCategories.LLMs.Title',
    description: 'Data.TileCategories.LLMs.Description',
  },
  {
    id: TileCategoryId.contentCreation,
    name: 'Data.TileCategories.ContentCreation.Title',
    description: 'Data.TileCategories.ContentCreation.Description',
  },
  { id: TileCategoryId.private, name: 'Data.TileCategories.Private.Title' },
  {
    id: TileCategoryId.managedPublic,
    name: 'Data.TileCategories.ManagedPublic.Title',
    description: 'Data.TileCategories.ManagedPublic.Description',
  },
  {
    id: TileCategoryId.public,
    name: 'Data.TileCategories.Public.Title',
    description: 'Data.TileCategories.Public.Description',
    links: {
      link: 'https://inside-docupedia.bosch.com/confluence2/genaiplatform/genai-platform-home-330139095.html',
    },
  },
  { id: TileCategoryId.bbm, name: 'Data.TileCategories.BBM.Title', description: 'Data.TileCategories.BBM.Description' },
  {
    id: TileCategoryId.brain,
    name: 'Data.TileCategories.Brain.Title',
    description: 'Data.TileCategories.Brain.Description',
  },
  { id: TileCategoryId.search, name: 'Data.TileCategories.Search.Title' },
];

export const indexedTileCategories = getIndexed(tileCategories);

export enum TileTabId {
  all = 'all',
  application = 'application',
  space = 'space',
  llm = 'llm',
}

export const tileTabs = [
  { id: TileTabId.all, name: 'Data.TileTabs.All' },
  { id: TileTabId.llm, name: 'Data.TileTabs.LLM' },
  { id: TileTabId.application, name: 'Data.TileTabs.Application' },
  { id: TileTabId.space, name: 'Data.TileTabs.Space' },
];
