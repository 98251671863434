import { useAuth } from '../context/auth';
import useSWRImmutable from 'swr/immutable';
import { UserService } from '../services/userService';
import { UserDetails, UserNames } from '../types/user';

export interface ExtendedUserDetails extends UserDetails, UserNames {
  initials: string;
}

export function useUserDetails(): ExtendedUserDetails {
  const { token } = useAuth();
  const { data } = useSWRImmutable<ExtendedUserDetails>(
    token && 'extendedUserDetails',
    async () => {
      const details = await UserService.getDetails(token);
      return {
        ...details,
        initials: details.givenName[0] + details.surname[0].toUpperCase(),
      };
    },
    { revalidateIfStale: false },
  );

  if (data) {
    return data;
  }

  return {
    displayName: '',
    givenName: '',
    surname: '',
    initials: '',
    costCenter: '',
    department: '',
    country: '',
    mail: '',
    id: '',
    userPrincipalName: '',
  };
}
