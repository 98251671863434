import React, { createContext, ReactNode, useContext, useMemo, useRef, useState } from 'react';
import { globalBreakpoints } from '@dssf/component-library';
import { ApiService, RequestError } from '../services/apiService';
import LocalStorage from '../services/localStorage';

const sideNavigationExpandedStateKey = 'SIDE_NAVIGATION_EXPANDED_STATE';

export interface LayoutContext {
  sideNavigationExpanded: boolean;
  setSideNavigationExpanded: (state: boolean) => void;
  globalError?: RequestError | null;
  notification: string | null;
  sendNotification: (message: string, duration?: number) => void;
}

const LayoutContext = createContext<LayoutContext>({
  sideNavigationExpanded: false,
  setSideNavigationExpanded: () => {},
  globalError: null,
  notification: null,
  sendNotification: () => {},
});

export function useLayout() {
  return useContext(LayoutContext);
}

export function LayoutContextProvider({
  sideNavigationInitiallyExpanded = false,
  children,
}: {
  sideNavigationInitiallyExpanded?: boolean;
  children: ReactNode;
}) {
  const [sideNavigationExpanded, setSideNavigationExpanded] = useState(
    LocalStorage.get(sideNavigationExpandedStateKey) ??
      ((typeof window === 'undefined' || window.innerWidth > globalBreakpoints.md) && sideNavigationInitiallyExpanded),
  );
  const [globalError, setGlobalError] = useState<RequestError | null>(null);
  const [notification, setNotification] = useState<string | null>(null);
  const notificationTimeoutRef = useRef<NodeJS.Timeout>();

  ApiService.registerGlobalErrorSetter(err => setGlobalError(err));

  const sendNotification = (message: string, duration = 3000) => {
    setNotification(message);
    clearTimeout(notificationTimeoutRef.current);
    notificationTimeoutRef.current = setTimeout(() => setNotification(null), duration);
  };

  const handleManualNavigationExpanded = (expanded: boolean) => {
    LocalStorage.set(sideNavigationExpandedStateKey, expanded);
    setSideNavigationExpanded(expanded);
  };

  const value = useMemo(
    () => ({
      sideNavigationExpanded,
      setSideNavigationExpanded: handleManualNavigationExpanded,
      globalError,
      notification,
      sendNotification,
    }),
    [sideNavigationExpanded, globalError, notification],
  );

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
}
